import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { HomeComponent } from './pages/home.component';
import { SpinnerInterceptor } from './spinner/spinner.interceptor';
import { userGuard } from './pages/user/user.guard';
import { adminGuard } from './admin.guard';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter([
      { path: '', component: HomeComponent, pathMatch: "full"},
      { path: 'user', loadComponent: () => import('./pages/admin/admin-home.component').then((c) => c.AdminHomeComponent), canMatch: [adminGuard]},
      { path: 'user', loadComponent: () => import('./pages/user/user-home.component').then((c) => c.UserHomeComponent), canMatch: [userGuard]},
    ]),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ]
};
