import { Component, ViewEncapsulation } from '@angular/core';
import { SpinnerService } from './spinner.service';


@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [],
  template: `
@if (this.spinner.getLoading()) {
  <div class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
}
`,
  styleUrls: ['./spinner.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SpinnerComponent {
  constructor(public spinner: SpinnerService) {}
}
