import { Component, OnDestroy, OnInit } from '@angular/core';

import { FormsModule, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CognitoService } from './cognito.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  errorMessage: string = '';
  isLoading: boolean = false;
  isLoggedIn: boolean = false;
  authenticationState: string = '';
  authenticationMessage: string = '';

  private loggedInListener: Subscription = Subscription.EMPTY;
  private authStateListener: Subscription = Subscription.EMPTY;
  private authMessageListener: Subscription = Subscription.EMPTY;

  constructor(
    private cognito: CognitoService,
  ) {}

  ngOnDestroy(): void {
    this.loggedInListener.unsubscribe();
    this.authStateListener.unsubscribe();
    this.authMessageListener.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoggedIn = this.cognito.getIsLoggedIn();

    this.loggedInListener = this.cognito.getAuthnStatusListener()
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });

    this.authStateListener = this.cognito.getAuthnStateListener()
      .subscribe((authState) => {
        this.authenticationState = authState;
      });

    this.authMessageListener = this.cognito.getAuthnMessageListener()
      .subscribe((authenticationMessage) => {
        this.authenticationMessage = authenticationMessage;
      });
  }

  ///
  ///
  ///

  onConfirmForgotPassword(form: NgForm) {
    const resetCode = form.value.resetCode;
    const newPw1 = form.value.newPassword;
    const newPw2 = form.value.newPasswordConfirm;

    if ((newPw1 == newPw2) && resetCode != undefined) {
      this.cognito.confirmForgotPassword(resetCode, newPw1);
    }
  }

  onLogout() {
    this.cognito.logout();
  }

  onRequestPasswordReset(form: NgForm) {
    if (form.invalid) return;

    this.cognito.forgotPassword(form.value.emailAddress);
  }

  onSetNewPassword(form: NgForm) {
    if (form.invalid) return;

    const newPw1 = form.value.newPassword;
    const newPw2 = form.value.newPasswordConfirm;

    if (newPw1 == newPw2) {
      this.cognito.respondToAuthChallengeNewPasswordRequired(newPw1);
    }
  }

  onSubmitChallenge(form: NgForm): void {
    if (form.invalid) return;


  }

  onSubmitUnPw(form: NgForm): void {
    if (form.invalid) {
      console.log('form is invalid')
      this.errorMessage = 'oops';
      this.setErrorMessage('Please completely fill the form.');
    } 

    if (form.value.email && form.value.password) {
      this.cognito.initiateAuth(form.value.email, form.value.password);
    }
  }



  setErrorMessage(message: string): void {
    console.log('setting message')
    this.errorMessage = message;
    console.log('setting timer')
    setTimeout(() => {this.errorMessage = ''; console.log('timer func executed')}, 3000)
    console.log('clearing message')
    this.errorMessage = '';
  }
}
