<div class="flex space-x-2 items-center w-full fixed -bottom-0">
  <p class="ml-2">Rincon Vista, LLC</p>
  <span>- <button routerLink="/">Home</button></span>

  @if (isLoggedIn) {
    <span>- <button routerLink="/user">Profile</button></span>
    <span>- <button (click)="onLogout()">Logout</button></span>
  }
  @if (false) {
    <span>
      <span>- <button routerLink="/login">Client Login</button></span>
    </span>
  }

  @if (user.isInGroup('admins')) {
    <span class="bg-blue-500 px-4">Administration</span>
  }

  <span class="grow"></span>

  @if (!production) {
    <span class="bg-red-500 px-4">{{ envenv }}</span>
  }
</div>
