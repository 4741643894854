import { Component, OnDestroy, OnInit } from '@angular/core';

import { Router, RouterLink } from '@angular/router';
import { CognitoService } from '../cognito/cognito.service';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UserService } from '../pages/user/user.service';

@Component({
  selector: 'page-footer',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnDestroy, OnInit {
  isLoggedIn: boolean = false;
  private authListenerSub: Subscription = Subscription.EMPTY;

  production = environment.production;
  url = environment.url;
  envenv = environment.environment;

  constructor(
    private cognito: CognitoService,
    private router: Router,
    public user: UserService,
  ) {}

  ngOnDestroy(): void {
    this.authListenerSub.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoggedIn = this.cognito.getIsLoggedIn();
    this.authListenerSub = this.cognito.getAuthnStatusListener()
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      })
  }

  onLogout(): void {
    if (this.cognito.logout()) {
      this.router.navigate(['/login']);
    }
  }
}