/**
 * The Authentication Service is responsible for communicating AWS Cognito
 *  and providing a generic login/logout interface to the application.
 * 
 * Future plans:
 * - Maybe this should manage authentication state instead
 * of the UserService.
 * 
 * - Maybe this should be a generic interface that can implement
 * multiple different authentication services.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from './cognito/cognito.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loggedInListener: Subscription = Subscription.EMPTY;

  constructor(
    private cognito: CognitoService,
    private router: Router
  ) {
    this.loggedInListener = this.cognito.getAuthnStatusListener()
      .subscribe((isLoggedIn) => {
        this.authnStatusChange(isLoggedIn);
      });

    console.log('AuthenticationService: initialized');
  }

  login(): boolean {

    return true;
  }

  logout(): boolean {
    if (this.cognito.logout()) {
      this.router.navigate(['/']);
      return true;
    }

    return false;
  }

  private authnStatusChange(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.router.navigate(['/user']);
    } else {
      this.router.navigate(['/']);
    }
  }
  
}
