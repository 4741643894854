import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { CognitoService } from 'src/app/cognito/cognito.service';

export const userGuard: CanMatchFn = (route, segments) => {
  console.log('userGuard:', inject(CognitoService).getIsLoggedIn());

  return inject(CognitoService).getIsLoggedIn()
    ? true
    : false;
};
