import { Component, OnDestroy, OnInit } from '@angular/core';

import { RouterLink } from '@angular/router';
import { CognitoService } from '../cognito/cognito.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'page-header',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnDestroy, OnInit {
  isLoggedIn: boolean = false;
  private authListenerSub: Subscription = Subscription.EMPTY;

  constructor(
    private cognito: CognitoService
  ) {}

  ngOnDestroy(): void {
    this.authListenerSub.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoggedIn = this.cognito.getIsLoggedIn();
    this.authListenerSub = this.cognito.getAuthnStatusListener()
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      })
  }

  onLogout(): void {
    this.cognito.logout();
  }
}
