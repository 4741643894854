import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CognitoService } from 'src/app/cognito/cognito.service';

import { environment } from 'src/environments/environment';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  private isLoggedIn: boolean = false;
  private loggedInListener: Subscription = Subscription.EMPTY;

  private profile: any = {};
  private stripe: any = {};
  private groups: string[] = [];

  constructor(
    private cognito: CognitoService,
    private http: HttpClient,
  ) { 
    this.isLoggedIn = this.cognito.getIsLoggedIn();
    
    this.loggedInListener = this.cognito.getAuthnStatusListener()
      .subscribe((isLoggedIn) => {
        this.authnStatusChange(isLoggedIn);
      });

      console.log('user service initialized')
  }

  ngOnDestroy(): void {
    this.loggedInListener.unsubscribe();
  }

  forceReloadProfile(): void {
    this.loadProfile();
  }

  getProfile(section?: string): any {

    console.log('UserService: getProfile()');

    // TODO: Change this to clearly indicate the time it was last retrived, versus modified.
    // The time modified will be needed to determine if the data is dirty.
    //
    if (( this.profile._lastUpdated - Date.now() ) > (1000 * 60 * 5) ) {
      this.loadProfile();
    }
    
    if (section) {
      if (section in this.profile) {
        return this.profile[section];
      } else {
        return {};
      }
    }

    return this.profile;
  }

  getStripe(): any {
    return this.stripe;
  }

  isInGroup(group: string): boolean {
    if (!this.groups) return false;

    if (this.groups.includes(group)) {
      return true;
    }

    return false;
  }

  saveUser(data: any): void {

    const accessToken = this.cognito.getAccessToken();
    if (!accessToken) return;

    const headers = {
      'Authorization': `Bearer ${accessToken}`
    }
    console.log(`Attempting to save user data to https://${environment.url.api}/user/data}`, this.profile, { headers });

    this.http.post<any>(`https://${environment.url.api}/user/data`, this.profile, { headers })
      .subscribe({
        next: (response) => {
          console.log('response from /user/data')
          console.log(JSON.stringify({response}));
        },

        error: ({ name, message }) => { console.log(`${name} -- ${message}`); }
      })
  }

  private authnStatusChange(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.isLoggedIn = true;
      this.loadProfile();
    } else {
      this.isLoggedIn = false;
      this.purgeData();
    }
  }

  private loadProfile(): void {

    const accessToken = this.cognito.getAccessToken();
    if (!accessToken) return;

    const jwtHelper = new JwtHelperService();
    const token = jwtHelper.decodeToken(accessToken);
    this.groups = token['cognito:groups'];

    const headers = {
      'Authorization': `Bearer ${accessToken}`
    }

    console.log(`attempting to load profile from https://${environment.url.api}/user/data}`, { headers })
    this.http.get<any>(`https://${environment.url.api}/user/data`, { headers })
      .subscribe({
        next: (response) => {

          if (!response.profile.user) {
            response.profile.user = {};
          }

          if (response.profile) {
            this.setProfile(response.profile);
          }

          if (response.stripe) {
            this.setStripe(response.stripe);
          }

        },

        error: ({ name, message }) => { console.log(`${name} -- ${message}`); }
      })
  }

  private setProfile(data: any): void {
    this.profile = data;
    this.profile._lastUpdated = Date.now();
  }

  private setStripe(data: any): void { 
    this.stripe = data;
  }
  
  private purgeData(): void {
    this.profile = {};
    this.stripe = {};
    this.groups = [];
  }
}

