import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  // HttpHandlerFn,
  HttpInterceptor
  // HttpInterceptorFn,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs';
import { SpinnerService } from './spinner.service';

// export const spinnerInterceptor: HttpInterceptorFn = (
//   req: HttpRequest<unknown>,
//   next: HttpHandlerFn
// ) => {
//   // const spinner = Inject(SpinnerService);
  

//   let totalRequests = 0;
//   let thanked = false;

//   // constructor(private spinner: SpinnerService) {}

//   totalRequests++;
//   console.log(`current http requests counted: ${totalRequests}`)
//   spinner.setLoading(true);

//   if (thanked == false) {
//     console.log('Thanks for the spinner code! https://danielk.tech/home/angular-how-to-add-a-loading-spinner')
//     thanked = true;
//   }
//   return next(req).pipe(
//     finalize(() => {
//       totalRequests--;
//       if (totalRequests == 0) {
//         spinner.setLoading(false);
//       }
//     })
//   );

// }


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  private totalRequests = 0;
  private thanked = false;

  constructor(private spinner: SpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalRequests++;
    this.spinner.setLoading(true);

    if (this.thanked == false) {
      console.log('Thanks for the spinner code! https://danielk.tech/home/angular-how-to-add-a-loading-spinner')
      this.thanked = true;
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.spinner.setLoading(false);
        }
      })
    );
  }
}
