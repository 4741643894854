
@if (!isLoggedIn && authenticationState === '') {
  <div class="max-w-sm mx-auto mt-4 shadow-md rounded-lg dark:shadow-stone-500 p-3">
    <form (submit)="onSubmitUnPw(loginForm)" #loginForm="ngForm">
      <h2 class="text-2xl pb-2">Client Login</h2>
      <div class="grid grid-cols-1 pb-2">
        <label for="emailInput">email address:</label>
        <input class="text-black" type="text" name="email" ngModel required #emailInput="ngModel" />
      </div>
      <div class="grid grid-cols-1 pb-2">
        <label for="passwordInput">password:</label>
        <input class="text-black" type="password" name="password" ngModel required #passwordInput="ngModel" />
      </div>
      <p class="text-red-500">{{this.authenticationMessage}}</p>
      <button class="mr-6" type="submit">Login</button>
      <button type="button" (click)="this.authenticationState = 'LocalRequestPasswordReset'" >Reset Password</button>
    </form>
  </div>
}

@if (isLoggedIn && authenticationState == '') {
  <div class="max-w-sm mt-8 mx-auto">
    <div class="mx-auto">
      <button (click)="onLogout()">Logout</button>
    </div>
  </div>
}

<!-- PasswordResetRequiredException -->
<div class="max-w-sm mx-auto">
  @switch (authenticationState) {
    @case ('PasswordResetRequiredException') {
      <div>
        <p>Password reset required</p>
        <button (click)="this.authenticationState=''">Reset</button>
        <form (submit)="onConfirmForgotPassword(confirmForgotPasswordForm)" #confirmForgotPasswordForm="ngForm">
          <h2 class="text-2xl pb-2">Enter your reset code and a new password:</h2>
          <div class="grid grid-cols=1 pb-2">
            <label for="resetCode">Reset Code:</label>
            <input class="text-black" type="text" name="resetCode" ngModel required #resetCode="ngModel" />
          </div>
          <div class="grid grid-cols=1 pb-2">
            <label for="newPasswordCo">New Password:</label>
            <input class="text-black" type="password" name="newPassword" ngModel required #newPassword="ngModel" />
          </div>
          <div class="grid grid-cols=1 pb-2">
            <label for="newPasswordConfirm">Confirm New Password:</label>
            <input class="text-black" type="password" name="newPasswordConfirm" ngModel required #newPasswordConfirm="ngModel" />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    }
    @case ('NEW_PASSWORD_REQUIRED') {
      <div>
        <form (submit)="onSetNewPassword(setNewPasswordForm)" #setNewPasswordForm="ngForm">
          <h2 class="text-2xl pb-2">You must change your password to continue:</h2>
          <div class="grid grid-cols=1 pb-2">
            <label for="newPasswordCo">New Password:</label>
            <input class="text-black" type="password" name="newPassword" ngModel required #newPassword="ngModel" />
          </div>
          <div class="grid grid-cols=1 pb-2">
            <label for="newPasswordConfirm">Confirm New Password:</label>
            <input class="text-black" type="password" name="newPasswordConfirm" ngModel required #newPasswordConfirm="ngModel" />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    }
    @case ('LocalRequestPasswordReset') {
      <div>
        <form (submit)="onRequestPasswordReset(requestPasswordResetForm)" #requestPasswordResetForm="ngForm">
          <div class="grid grid-cols1 pb-2">
            <label for="emailAddress">Email Address:</label>
            <input class="text-black" type="text" name="emailAddress" ngModel required #emailAddress="ngModel" />
          </div>
          <button class="mr-6" type="submit">Request New Password</button>
          <button type="button" (click)="authenticationState=''" >Cancel</button>
        </form>
      </div>
    }
    @case ('mfa challenge') {
      <div>
        <form (submit)="onSubmitChallenge(mfaChallengeForm)" #mfaChallengeForm="ngForm">
          <label for="mfaResponse">2FA</label>
          <input class="text-black" type="text" name="mfaChallengeForm" ngModel required #mfaChallengeForm="ngModel" />
        </form>
      </div>
    }
    @default {
      <div>
        <p>{{authenticationState}}</p>
      </div>
    }
  }
</div>