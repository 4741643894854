export const environment = {
    production: false,
    environment: 'dev',
    cognito: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_pTR12w0Ao',
        userPoolClientId: '2hj8t9oqsdics1l0a8hihuuqvg',
        apiEndpoint: 'https://cognito-idp.us-east-1.amazonaws.com',
    },
    url: {
        web: 'dev.rinconvista.com',
        // api: 'http://localhost:3000',
        api: 'api.dev.rinconvista.com',
        // login: 'https://login.dev.rinconvista.com/oauth2/authorize?client_id=5oteod37bcpogtj5vls38bq268&response_type=code&scope=email+openid+profile&redirect_uri=http%3A%2F%2Flocalhost:4200%2Fcognito%2Freturn',
        // login: 'http://localhost:3000/login',
        login: 'https://api.dev.rinconvista.com/login',
        static: 'static.rinconvista.com',
    }
};
