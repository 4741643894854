import { Component, OnInit } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './page-components/header.component';
import { FooterComponent } from './page-components/footer.component';
import { CognitoService } from './cognito/cognito.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { AuthenticationService } from './authentication.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, FooterComponent, SpinnerComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'frontend-web';

  constructor(
    private auth: AuthenticationService,
    private cognito: CognitoService
  ) {}

  ngOnInit(): void {
    console.log('AppComponent initialized and is attempting to reload the session');
    this.cognito.reloadSession();
  }
}
