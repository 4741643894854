import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  ChangePasswordCommandInput,
  ChangePasswordCommand,
  ChangePasswordCommandOutput,
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  InitiateAuthCommandOutput,
  RespondToAuthChallengeCommandInput,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandOutput,
} from "@aws-sdk/client-cognito-identity-provider";



export interface AuthData {
  accessToken: string | undefined,
  idToken: string | undefined,
  refreshToken: string | undefined,
  tokenType: string | undefined,
  tokenExpiration: number | undefined,
}

@Injectable({
  providedIn: 'root',
  
})
export class CognitoService {
  private isLoggedin: boolean = false;
  private isAdmin: boolean = false;
  authenticationState: string = '';
  authenticationMessage: string = '';
  expirationTimer: ReturnType<typeof setTimeout> | undefined = undefined;

  private blankAuth: AuthData = {
    accessToken: '',
    idToken: '',
    refreshToken: '',
    tokenType: '',
    tokenExpiration: 0
  }

  private authData: AuthData = this.blankAuth;
  
  /*
   * authFlow holds credentials during a multi step auth process
   */
  private authFlow: {
    username: string,
    password: string,
    action: string,
    message: string,
    session: string | undefined,
  } = {
    username: '',
    password: '',
    action: '',
    message: '',
    session: undefined,
  }

  // observables to allow athentication status.
  private authnStatusListener = new Subject<boolean>;
  private authnStateListener = new Subject<string>;
  private authnMessageListener = new Subject<string>;

  constructor (
    private http: HttpClient,
  ) { }


  getAccessToken(): string | undefined {
    return this.authData.accessToken;
  }

  /*
   * ----- Start Status Services
   */

  getAuthnStatusListener() {
    return this.authnStatusListener.asObservable();
  }

  getAuthnStateListener() {
    return this.authnStateListener.asObservable();
  }

  getAuthnMessageListener() {
    return this.authnMessageListener.asObservable();
  }

  private setAuthnState(state: string): void {
    this.authenticationState = state;
    this.authnStateListener.next(state);
  }

  private setAuthnMessage(message: string): void {
    this.authenticationMessage = message;
    this.authnMessageListener.next(message);
  }

  getIsAdmin() {
    return this.isAdmin;
  }

  getIsLoggedIn() {
    return this.isLoggedin;
  }
  

  /*
   * ----- 
   */

  // exchangeAuthCode(code: string): void {
  //   this.isLoggedin = false;
  //   this.isAdmin = false;
  //   this.authData.accessToken = '';
  //   this.authData.idToken = '';
  //   this.authData.refreshToken = '';
  //   this.authData.tokenType = '';
  //   this.authData.tokenExpiration = 0;

  //   this.http
  //     .get<{
  //       id_token: string,
  //       access_token: string,
  //       refresh_token: string,
  //       expires_in: number,
  //       token_type: string
  //     }>(`${environment.url.api}/cognito/return?code=${code}`)
  //     .subscribe({
  //       next: (response) => this.handleAccessdata(response),
  //       error: (error) => this.handleHttpError(error),
  //     });
  // }

  private handleAccessdata(accessData: AuthData) {
    this.authData.accessToken = accessData.accessToken;
    this.authData.idToken = accessData.idToken;
    this.authData.refreshToken = accessData.refreshToken;
    this.authData.tokenType = accessData.tokenType;
    this.authData.tokenExpiration = (accessData.tokenExpiration! * 1000) + Date.now();
    console.log(`Token expires at ${this.authData.tokenExpiration} which is ${this.authData.tokenExpiration - Date.now()} ms from now.`);
    this.isLoggedin = true;
    this.authnStatusListener.next(true);

    this.saveAuthData(this.authData);
  }

  login(): void {}

  logout(): boolean {
    this.deleteAuthData(this.authData, this.isAdmin)
    this.authData = this.blankAuth;
    this.isLoggedin = false;
    this.authnStatusListener.next(false);
    this.authnStateListener.next('');
    
    if (this.expirationTimer) {
      clearTimeout(this.expirationTimer);
      this.expirationTimer = undefined;
    }
    
    return true;
  }

  reloadSession(): void {
    const tokens: AuthData = this.loadAuthData();

    if (tokens.tokenExpiration && tokens.tokenExpiration > Date.now()) {
      this.authData = tokens;
      this.isLoggedin = true;
      this.authnStatusListener.next(true);
    } else {
      this.deleteAuthData(tokens);
    }

    return;
  }

///////////////////////////////////////////////////////////////////////////////////////////////////
//////////                         START LOCAL STORAGE FUNCTIONS                         //////////
///////////////////////////////////////////////////////////////////////////////////////////////////

  private deleteAuthData(authData: AuthData, isAdmin?: boolean): void {
    for (const key in authData) {
      localStorage.removeItem(key);
    }

    if (this.isAdmin) {
      localStorage.removeItem('adam');
    }

    return;
  }

  private loadAuthData(): AuthData {

    const tokens: AuthData = {
      accessToken: <string>localStorage.getItem('accessToken'),
      idToken: <string>localStorage.getItem('idToken'),
      refreshToken: <string>localStorage.getItem('refreshToken'),
      tokenType: <string>localStorage.getItem('tokenType'),
      tokenExpiration: <number><any>localStorage.getItem('tokenExpiration')
    }

    const adam = localStorage.getItem('adam');
    if (adam) {
      console.log(`loaded truthy ${adam} from localstorage so setting admin flag to true.`);
      this.isAdmin = true;
    }
    

    return tokens;
  }

  private saveAuthData(authData: any, isAdmin?: boolean) {

    for (const key in authData) {
      localStorage.setItem(key, authData[key])
    }

    if (isAdmin) {
      localStorage.setItem('adam', '1')
    }
  }

  private setExpirationTimer(seconds: number): ReturnType<typeof setTimeout> {
    return setTimeout(() => {
      this.logout();
    }, seconds * 1000);
  }

///////////////////////////////////////////////////////////////////////////////////////////////////
//////////                          END LOCAL STORAGE FUNCTIONS                          //////////
///////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////
//////////                            START COGNITO FUNCTIONS                            //////////
///////////////////////////////////////////////////////////////////////////////////////////////////

  async changePassword(newPassword: string, oldPassword?: string) {
    const PreviousPassword = oldPassword ? oldPassword : this.authFlow.password;
    const ProposedPassword = newPassword;
    const AccessToken = this.authFlow.session;

    const input: ChangePasswordCommandInput = {
      PreviousPassword, ProposedPassword, AccessToken
    }

    const client = new CognitoIdentityProviderClient({ region: 'us-east-1' });
    const command = new ChangePasswordCommand(input);
    let response: ChangePasswordCommandOutput;

    try {
      response = await client.send(command);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    
  }
  

  async confirmForgotPassword(ConfirmationCode: string, Password: string) {
    console.log('----- entered confirmForgotPassword')
    const endpoint = environment.cognito.apiEndpoint;
    const ClientId = environment.cognito.userPoolClientId;

    const Username: string = this.authFlow.username;

    const requestHeaders = {
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.ConfirmForgotPassword',
    };

    const requestBody = {
      ClientId,
      Username,
      Password,
      ConfirmationCode,
    };

    const client = new CognitoIdentityProviderClient({ region: 'us-east-1' });
    const command = new ConfirmForgotPasswordCommand(requestBody);

    try {
      const response = await client.send(command);
      this.setAuthnState('');
    } catch (error) {
      console.log(error);
    }

  }

  forgotPassword(Username: string) {
    console.log('----- entered forgotPassword()')
    const endpoint = environment.cognito.apiEndpoint;
    const ClientId = environment.cognito.userPoolClientId;

    const requestHeaders = {
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.ForgotPassword',
    }

    const requestBody = {
      Action: 'ForgotPassword',
      ClientId,
      Username,
    }

    this.authFlow.action = 'ForgotPassword';
    this.authFlow.username = Username;

    this.http.post<any>(endpoint, requestBody, { headers: requestHeaders })
      .subscribe({
        next: response => { this.processAuthResponse(response) },
        error: error => { this.processHttpError(error) },
      });

  }

  async initiateAuth(USERNAME: string, PASSWORD: string) {
    console.log('----- entered initiateAuth()')
    const endpoint = environment.cognito.apiEndpoint;
    const ClientId = environment.cognito.userPoolClientId;

    const input: InitiateAuthCommandInput = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId,
      AuthParameters: {
        USERNAME, PASSWORD
      }
    };

    const client = new CognitoIdentityProviderClient({ region: 'us-east-1' });
    const command = new InitiateAuthCommand(input);
    let response: InitiateAuthCommandOutput;
    let errorDetail;

    try {
      response = await client.send(command);
      console.log(response)
    } catch (error: any) {
      console.log(error);

      const errorType = error.name;

      switch(errorType) {
        case 'UserNotFoundException': {
          // do something
          this.setAuthnMessage('Username / email or password were not correct. Please try again.')
          break;
        }
        case 'NotAuthorizedException': {
          this.setAuthnMessage('Username / email or password were not correct. Please try again.')
          break;
        }
        default: {
          this.setAuthnMessage(errorType);
          // --TODO-- put a default error message
        }
      }
      return;

      /*
      {
        "name":"UserNotFoundException",
        "$fault":"client",
        "$metadata": {
          "httpStatusCode":400,
          "requestId":"c5346ebb-92d1-47b5-bec2-087545e47b3e",
          "attempts":1,
          "totalRetryDelay":0},
          "__type":"UserNotFoundException"}
      */
    }
    
    if (response.AuthenticationResult) {
      // successful login

      const authData: AuthData = {
        accessToken: response.AuthenticationResult.AccessToken,
        idToken: response.AuthenticationResult.IdToken,
        refreshToken: response.AuthenticationResult.RefreshToken,
        tokenType: response.AuthenticationResult.TokenType,
        tokenExpiration: response.AuthenticationResult.ExpiresIn
      }
      this.authFlow.action = '';
      this.authFlow.username = '';
      this.authFlow.password = '';
      this.authFlow.message = '';

      this.expirationTimer = this.setExpirationTimer(response.AuthenticationResult.ExpiresIn!);

      this.handleAccessdata(authData);
      return;
    }

    if (response.ChallengeName) {
      // user must complete an authentication challenge

      if (response.ChallengeName == 'NEW_PASSWORD_REQUIRED') {
        this.authFlow.action = 'ForgotPassword';
        this.authFlow.username = USERNAME;
        this.authFlow.password = PASSWORD;
        this.authFlow.message = 'Please reset your password.';
        this.authFlow.session = response.Session;
        this.setAuthnState('NEW_PASSWORD_REQUIRED');
        return;
      }

      /*
      *
      * --TODO-- MFA implementation
      * 
      */

    }

  }

  private resendConfirmationCode(): void {

  }

  async respondToAuthChallengeNewPasswordRequired(NEW_PASSWORD: string, userAttributes?: any) {
    const USERNAME = this.authFlow.username;
    const ChallengeName = 'NEW_PASSWORD_REQUIRED'; 
    const ClientId = environment.cognito.userPoolClientId;

    const input: RespondToAuthChallengeCommandInput = {
      ChallengeName,
      ChallengeResponses: {
        USERNAME, NEW_PASSWORD
      },
      ClientId,
      Session: this.authFlow.session,
    };

    const client = new CognitoIdentityProviderClient({ region: 'us-east-1' });
    const command = new RespondToAuthChallengeCommand(input);
    let response: RespondToAuthChallengeCommandOutput;

    try {
      response = await client.send(command);
      console.log(response);

      if (response.AuthenticationResult) {
        // successful login
  
        const authData: AuthData = {
          accessToken: response.AuthenticationResult.AccessToken,
          idToken: response.AuthenticationResult.IdToken,
          refreshToken: response.AuthenticationResult.RefreshToken,
          tokenType: response.AuthenticationResult.TokenType,
          tokenExpiration: response.AuthenticationResult.ExpiresIn
        }
        this.authFlow.action = '';
        this.authFlow.username = '';
        this.authFlow.password = '';
        this.authFlow.message = '';
  
        this.expirationTimer = this.setExpirationTimer(response.AuthenticationResult.ExpiresIn!);
  
        this.handleAccessdata(authData);
        return;
      }
    
    } catch (error) {
      console.log(error);
    }

    

  }
  

  /*
   *
   *
   * 
   */

  private processAuthResponse(response: any) {
    let authResult;
    console.log("PAR: Oy! processAuthResponse at your service... er, function. Whatever. I'm loaded. jaja");
    console.log(response);

    if (response.ChallengeParameters) {
      console.log("PAR: I found a ChallengeParameters!")
      console.log("PAR: Moving on...")
    }

    if (response.AuthenticationResult) {
      console.log('PAR: I found an AuthenticationResult!')
      authResult = response.AuthenticationResult;

    }

    if (response.CodeDeliveryDetails) {
      if (this.authFlow.action == 'ForgotPassword') {
        this.authFlow.message = 'Check your email for your password reset code.';
        this.setAuthnState('PasswordResetRequiredException');
      }
    }
    
  }

  private processHttpError(error: any): void {
    console.log(`Request Error: ${error.error.message}`);
    console.log(`Error Type: ${error.error.__type}`)

    if (error.error.__type) {
      switch(error.error.__type) {
        case 'PasswordResetRequiredException': {
          this.setAuthnState(error.error.__type);
          break;
        }
        
        default: {
          this.setAuthnState(error.error.__type);
        }
      }
    }
  }


///////////////////////////////////////////////////////////////////////////////////////////////////
//////////                             END COGNITO FUNCTIONS                             //////////
///////////////////////////////////////////////////////////////////////////////////////////////////



}
